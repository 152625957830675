<template>
  <ConfirmDialog
    :open="useDialogStore().confirmRequestAccept.visible"
    type="primary"
    :text="useDialogStore().confirmRequestAccept.text"
    :title="useDialogStore().confirmRequestAccept.title"
    :accept-text="useDialogStore().confirmRequestAccept.acceptText"
    @accept="useDialogStore().confirmRequestAccept.accept()"
    @dismiss="useDialogStore().close('confirmRequestAccept');"
  />
</template>

<script>
import ConfirmDialog from '~/components/dialogs/ConfirmDialog';

export default {
  components: {
    ConfirmDialog,
  },
};
</script>
