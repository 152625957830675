<template>
  <transition name="list">
    <div
      v-if="isOpen"
      v-loading="isLoading"
      class="InfoBox tw-flex tw-flex-col tw-items-center"
    >
      <icon-transfer class="tw-text-primary-500 tw-mb-3" />
      <div class="tw-text-center">
        <strong class="tw-text-primary-500 tw-block tw-mb-1">
          Předání požadavku
        </strong>
        <p class="tw-text-text-light">
          {{ toForward.from_operator.first_name }} {{ toForward.from_operator.last_name }} vám chce předat požadavek
        </p>
      </div>
      <div class="tw-mt-4 tw-flex tw-justify-between tw-w-full">
        <div class="tw-flex">
          <el-tooltip
            content="Přijmout"
          >
            <el-button
              type="success"
              circle
              @click="handleAccept"
            >
              <icon-check />
            </el-button>
          </el-tooltip>
          <el-tooltip
            content="Zobrazit požadavek"
          >
            <nuxt-link
              :to="localePath(`/operator/requests/${toForward.id}/detail`)"
              class="tw-ml-3"
            >
              <el-button
                plain
                circle
              >
                <icon-right />
              </el-button>
            </nuxt-link>
          </el-tooltip>
        </div>
        <el-tooltip
          content="Zamítnout"
        >
          <el-button
            type="danger"
            circle
            @click="handleReject"
          >
            <icon-close />
          </el-button>
        </el-tooltip>
      </div>

      <audio ref="requestForwardAudio">
        <source
          src="/sounds/request_forward.mp3"
          type="audio/mp3"
        >
      </audio>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
      isLoading: false,
    };
  },

  computed: {
    toForward() {
      return this.$store.state.operator.requests.toForward;
    },
  },

  watch: {
    toForward: {
      handler(toForward) {
        this.isOpen = !!toForward;

        this.$nextTick(() => {
          if (this.isOpen && this.$refs.requestForwardAudio) {
            this.$refs.requestForwardAudio.play().catch((error) => {
              console.warn(error);
            });

            this.$store.dispatch('chat/hideChat', {
              room_id: 1,
            });
          }
        });
      },
    },
  },

  methods: {
    close() {
      this.isOpen = false;
      this.$store.commit('operator/requests/TO_FORWARD_SET', null);
    },

    async handleAccept() {
      this.isLoading = true;

      const data = {
        operator_id: useAuthStore().user?.id,
      };

      if (this.toForward.service_id) {
        data.service_id = this.toForward.service_id;
        data.type_id = this.toForward.service_id == 1 ? 3 : null;
      }

      if (this.toForward.equip_reports) {
        this.$store.dispatch('operator/equip/saveEquipReports', {
          requestId: this.toForward.id,
          data: this.toForward.equip_reports,
        });
      }

      await this.$store.dispatch('operator/requests/updateRequest', {
        id: this.toForward.id,
        data,
      });

      this.isLoading = false;

      const requestId = this.toForward.id;
      this.$router.push(this.localePath(`/operator/requests/${requestId}/chat`), () => {
        this.$store.dispatch('chat/fetchMessages', { request_id: requestId });
      });

      this.close();
    },
    handleReject() {
      this.$socket.sendObj({
        command: 'decline_forward_request',
        request_id: this.toForward.id,
        forwarded_from: this.toForward.from_operator.id,
      });

      this.close();
    },
  },
};
</script>

<style scoped>
.InfoBox {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 123;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 20px 50px rgb(0 0 0 / 0.1);

  padding: 24px;
}
</style>
