<template>
  <BaseDialog
    :open="visible"
    width="660px"
    @dismiss="close"
  >
    <template #header>
      <h4 class="tw-text-primary-500 tw-flex tw-items-center">
        <icon-transfer class="tw-mr-3" />
        <span>Předat požadavek</span>
      </h4>
    </template>
    <template #content>
      <div
        v-loading="isLoading"
        class="DialogBody"
      >
        <div
          v-if="!isLoading && (!onlineUsers || !onlineUsers.length)"
          class="tw-h-full tw-flex tw-items-center tw-justify-center tw-flex-col tw-mt-4 tw-mb-4"
        >
          <icon-user-xl class="color-muted tw-mb-4 h-48p" />
          <div class="like-h4 tw-mb-2">
            Nikdo tu není
          </div>
          <div class="tw-text-text-light">
            Žádný operátor není online.
          </div>
        </div>
        <transition-group name="list">
          <div
            v-for="user in onlineUsers"
            :key="user.id"
            class="UserRow"
            :class="{ 'is-selected': selected == user.id }"
            @click="selectUser(user.id)"
          >
            <div class="UserRowAvatar">
              <UserAvatar :user="user" />
            </div>
            <div class="UserRowName tw-font-semibold">
              {{ user.first_name }} {{ user.last_name }}
            </div>
            <div class="UserRowShift tw-flex tw-items-center">
              <div
                v-if="user.current_shifts && user.current_shifts.length"
              >
                <ServiceIconXl
                  :service-id="user.current_shifts[0].request_services[0]"
                  size="small"
                  class="tw-mr-3"
                />
              </div>

              <div
                v-else
                class="UserRowShift tw-text-text-light"
              >
                Nemá službu
              </div>

              <div
                v-for="(type, index) in $store.state.requests.types"
                :key="index"
              >
                <el-tooltip
                  :content="type.name"
                >
                  <component
                    :is="type.icon"
                    :class="`tw-block tw-w-4 tw-h-auto color-${type.color} tw-ml-3`"
                    :hidden="!(user.current_shifts.some((currentShift) => currentShift.request_types.includes(type.id) || type.id === 1))"
                  />
                </el-tooltip>
              </div>
            </div>
            <div class="tw-text-text-light">
              <span
                v-if="(user.requests_in_progress + user.requests_paused) > 0"
                class="tw-italic"
              >
                Řeší
                <strong class="tw-text-primary-500 tw-whitespace-nowrap">
                  {{ user.requests_in_progress + user.requests_paused }}
                  {{ pluralize((user.requests_in_progress + user.requests_paused), 'požadavek', 'požadavky', 'požadavků') }}
                </strong>
              </span>
              <span
                v-else
                class="tw-italic"
              >
                Volný
              </span>
            </div>
            <div class="UserRowCheck">
              <icon-check
                v-if="selected == user.id"
                class="color-success"
              />
            </div>
          </div>
        </transition-group>
      </div>
    </template>
    <template #footer>
      <div class="tw-flex tw-justify-between DialogFooter">
        <el-button
          class="el-button--no-shadow"
          @click="close"
        >
          Zrušit
        </el-button>
        <div>
          <el-button
            v-if="selected && serviceId == 1"
            type="primary"
            @click="submit(2)"
          >
            <icon-transfer class="tw-mr-2" />
            <span>
              Předat jako přepis
            </span>
          </el-button>
          <el-button
            v-if="selected && serviceId == 2"
            type="primary"
            @click="submit(1)"
          >
            <icon-transfer class="tw-mr-2" />
            <span>
              Předat jako tlumočení
            </span>
          </el-button>

          <el-button
            type="primary"
            :disabled="!selected"
            @click="submit()"
          >
            <icon-transfer class="tw-mr-2" />
            <span>
              Předat
            </span>
          </el-button>
        </div>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
import IconLoading from '~/assets/img/icons/icon-loading.svg?url';

export default {
  data() {
    return {
      isLoading: false,
      notifyIsLoading: null,
    };
  },

  computed: {
    visible() {
      return useDialogStore().requestForward.visible;
    },
    id() {
      return useDialogStore().requestForward.id;
    },
    serviceId() {
      return useDialogStore().requestForward.service_id;
    },
    selected() {
      return useDialogStore().requestForward.selected;
    },
    onlineUsers() {
      return this.$store.state.operator.operators.operators.filter((operator) => operator.is_online && operator.id !== useAuthStore().user?.id);
    },
    forwarded() {
      return this.$store.state.operator.requests.forwarded;
    },
    requests() {
      return this.$store.state.operator.requests.requests;
    },
  },

  watch: {
    async visible(value) {
      if (value) {
        this.selectUser(null);

        this.isLoading = true;
        await this.$store.dispatch('operator/operators/getOperators');
        this.isLoading = false;
      }
    },

    requests: {
      handler() {
        if (this.notifyIsLoading) {
          const request = this.requests.find((request) => request.id === this.id);
          if (request && request.operator && request.operator.id !== useAuthStore().user?.id) {
            this.notifyIsLoading.close();
          }
        }
      },
    },

    forwarded: {
      handler(forwarded) {
        if (this.notifyIsLoading) {
          this.notifyIsLoading.close();
        }

        if (forwarded && !forwarded.isSuccess) {
          ElNotification.warning({
            title: 'Operátor nepřijal předání požadavku',
            duration: 15 * 1000,
            // message: '',
            position: 'bottom-right',
          });

          this.$store.commit('operator/requests/FORWARDED_SET', null);
        }
      },
    },
  },

  methods: {
    close() {
      useDialogStore().close('requestForward');
    },
    selectUser(id) {
      useDialogStore().update({ dialogName: 'requestForward', data: { selected: id } });
    },
    submit(serviceId) {
      this.$socket.sendObj({
        command: 'forward_request',
        request_id: this.id,
        forwarded_to: this.selected,
        service_id: serviceId,
        equip_reports: this.$store.getters['operator/equip/getEquipReports']({ requestId: this.id }),
      });

      this.close();

      this.notifyIsLoading = ElNotification({
        title: '',
        duration: 0,
        message: `
          <div class="tw-mt-1 tw-mb-4"><img src="${IconLoading}" width="26" height="26"></div>
          <strong class="tw-text-left tw-block tw-mt-1">Předání požadavku čeká na přijmutí</strong>
        `,
        dangerouslyUseHTMLString: true,
        position: 'bottom-right',
      });
    },
  },
};
</script>

<style scoped>
.DialogBody {
  margin-bottom: 32px;
}

.DialogFooter {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 18px;
}

.UserRow {
  border-bottom: 1px solid theme('colors.border');
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover,
  &.is-selected {
    @apply tw-bg-light;
  }

  &Avatar {
    margin-right: 20px;
  }

  &Name {
    width: 180px;
  }

  &Shift {
    width: 150px;
  }

  &Check {
    width: 80px;
    padding-right: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
