<template>
  <AppHeader>
    <template #navigation>
      <nuxt-link
        :to="localePath('/operator/requests')"
        exact
      >
        <el-popover
          placement="bottom"
          width="750"
          trigger="hover"
          :visible-arrow="false"
          popper-class="HeaderNavigationItemPopover tw-shadow-base tw-rounded-sm tw-max-w-full tw-border-none"
          :disabled="pendingRequests.length === 0"
        >
          <OperatorAppHeaderRequests
            v-loading="isPendingRequestsLoading"
            :requests="pendingRequests"
          />

          <template #reference>
            <div
              class="tw-h-12 tw-flex tw-items-center"
            >
              Požadavky

              <el-badge
                v-if="pendingRequests.length > 0"
                :value="pendingRequests.length"
                :max="99"
                class="tw-absolute tw-ml-1 tw-left-full tw-top-1/2 -tw-translate-y-1/2"
                type="danger"
              />
            </div>
          </template>
        </el-popover>
      </nuxt-link>
      <nuxt-link :to="localePath('/operator/users/clients')">
        Klienti
      </nuxt-link>
      <nuxt-link
        v-if="useAuthStore().user.lang !== 'ua'"
        :to="localePath('/operator/users/organizations')"
      >
        Organizace
      </nuxt-link>
      <nuxt-link :to="localePath('/operator/users/operators')">
        Tlumočníci
      </nuxt-link>
      <nuxt-link :to="localePath('/operator/shifts')">
        Služby
      </nuxt-link>
    </template>
    <template #right>
      <el-button
        v-if="useAuthStore().user?.lang !== 'ua'"
        circle
        plain
        class="tw-border-none tw-bg-white tw-bg-opacity-20 hover:tw-bg-opacity-30 tw-text-white"
        @click="$store.dispatch('chat/openChat', {
          room_id: 1,
        })"
      >
        <icon-chat />
      </el-button>

      <OperatorAppHeaderCall
        v-if="useAuthStore().user?.lang !== 'ua'"
        class="tw-ml-4"
      />
    </template>
  </AppHeader>
</template>

<script setup>
// Refs
const isPendingRequestsLoading = ref(false);

// Computed
const pendingRequests = computed(() => useNuxtApp().$store.state.operator.requests.pendingRequests);

// Methods
const loadPendingRequests = _debounce(async function() {
  isPendingRequestsLoading.value = true;
  await useNuxtApp().$store.dispatch('operator/requests/getPendingRequests');
  isPendingRequestsLoading.value = false;
}, 500);

// Lifecycle
onMounted(() => {
  loadPendingRequests();
});
</script>
