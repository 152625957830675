<template>
  <main
    :class="useRoute().meta?.fillHeight ? 'FillHeight' : ''"
  >
    <template v-if="useAuthStore().isLoggedIn">
      <div
        v-if="useRoute().meta?.lightBackground"
        class="layout-fixed-bg tw-bg-light"
      />

      <OperatorAppHeader />

      <slot />

      <OperatorChats class="OperatorChats" />

      <RequestForwardDialog />
      <RequestChooseAssistantDialog />
      <OperatorRequestForwardInfoBox />
      <OperatorRequestAssistantAccept />
      <OperatorCallingBox v-if="useOperatorCallingStore().number && useOperatorCallingStore().token" />
      <OperatorRequestsSoundNotifications />
      <ConfirmRequestAcceptDialog />
      <CapacitorCodePush v-if="useRuntimeConfig().public.isIOSApp" />
    </template>
    <div
      v-else
      v-loading="true"
      class="tw-min-h-20"
    />
  </main>
</template>

<style scoped>
main {
  &.FillHeight {
    padding-bottom: 0;
    height: 100vh;
    overflow: hidden;

    display: flex;
    flex-direction: column;
  }

  .OperatorChats {
    position: fixed;
    bottom: 0;
  }
}
</style>
