<template>
  <BaseDialog
    :open="visible"
    width="660px"
    @dismiss="close"
  >
    <template #header>
      <h4 class="tw-text-primary-500 tw-flex tw-items-center">
        <icon-account-arrow-left-outline class="tw-w-5 tw-h-auto" />
        <span>Přizvat do požadavku</span>
      </h4>
    </template>
    <template #content>
      <div

        v-loading="isLoading"
        class="DialogBody"
      >
        <div
          v-if="!isLoading && (!onlineAssistanceOperators || !onlineAssistanceOperators.length)"
          class="tw-h-full tw-flex tw-items-center tw-justify-center tw-flex-col tw-mt-4 tw-mb-4"
        >
          <icon-user-xl class="color-muted tw-mb-4 h-48p" />
          <div class="like-h4 tw-mb-2">
            Nikdo tu není
          </div>
          <div class="tw-text-text-light">
            Žádný tlumočník bez služby není online.
          </div>
        </div>
        <transition-group name="list">
          <div
            v-for="user in onlineAssistanceOperators"
            :key="user.id"
            class="UserRow"
            :class="{ 'is-selected': selected == user.id }"
            @click="selectUser(user.id)"
          >
            <div class="UserRowAvatar">
              <UserAvatar :user="user" />
            </div>
            <div class="UserRowName tw-font-semibold">
              {{ user.first_name }} {{ user.last_name }}
            </div>
            <div class="tw-text-text-light">
              <span
                v-if="(user.requests_in_progress + user.requests_paused) > 0"
                class="tw-italic"
              >
                Řeší
                <strong class="tw-text-primary-500 tw-whitespace-nowrap">
                  {{ user.requests_in_progress + user.requests_paused }}
                  {{ pluralize((user.requests_in_progress + user.requests_paused), 'požadavek', 'požadavky', 'požadavků') }}
                </strong>
              </span>
              <span
                v-else
                class="tw-italic"
              >
                Volný
              </span>
            </div>
            <div class="UserRowCheck">
              <icon-check
                v-if="selected == user.id"
                class="color-success"
              />
            </div>
          </div>
        </transition-group>
      </div>
    </template>
    <template #footer>
      <div class="tw-flex tw-justify-between DialogFooter">
        <el-button
          class="el-button--no-shadow"
          @click="close"
        >
          Zrušit
        </el-button>
        <div>
          <el-button
            type="primary"
            :disabled="!selected"
            @click="submit()"
          >
            <icon-account-arrow-left-outline class="tw-mr-2 tw-w-4 tw-h-auto" />
            <span>
              Přizvat
            </span>
          </el-button>
        </div>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
import IconLoading from '~/assets/img/icons/icon-loading.svg?url';

export default {
  data() {
    return {
      isLoading: false,
      notifyIsLoading: null,
    };
  },

  computed: {
    visible() {
      return useDialogStore().chooseAssistant.visible;
    },
    id() {
      return useDialogStore().chooseAssistant.id;
    },
    selected() {
      return useDialogStore().chooseAssistant.selected;
    },
    onlineAssistanceOperators() {
      return this.$store.state.operator.operators.operators.filter((operator) => operator.is_online && operator.id !== useAuthStore().user?.id && operator.current_shifts.some((shift) => shift?.request_services?.includes(3)));
    },
    invitedAssistantResponse() {
      return this.$store.state.operator.requests.invitedAssistantResponse;
    },
    requests() {
      return this.$store.state.operator.requests.requests;
    },
  },

  watch: {
    visible: {
      async handler(value) {
        if (value) {
          this.selectUser(null);

          this.isLoading = true;
          await this.$store.dispatch('operator/operators/getOperators');
          this.isLoading = false;
        }
      },
      immediate: true,
    },

    requests: {
      handler() {
        if (this.notifyIsLoading) {
          const request = this.requests.find((request) => request.id === this.id);
          if (request && request.assistant && request.assistant.id !== useAuthStore().user?.id) {
            this.notifyIsLoading.close();
          }
        }
      },
    },

    invitedAssistantResponse: {
      handler(invitedAssistantResponse) {
        if (this.notifyIsLoading) {
          this.notifyIsLoading.close();
        }

        if (invitedAssistantResponse && !invitedAssistantResponse.isSuccess) {
          ElNotification.warning({
            title: 'Operátor nepřijal pozvání do požadavku',
            duration: 15 * 1000,
            // message: '',
            position: 'bottom-right',
          });

          this.$store.commit('operator/requests/INVITE_ASSISTANT_RESPONSE_SET', null);
        }
      },
    },
  },

  methods: {
    close() {
      useDialogStore().close('chooseAssistant');
    },
    selectUser(id) {
      useDialogStore().update({ dialogName: 'chooseAssistant', data: { selected: id } });
    },
    submit() {
      this.$socket.sendObj({
        command: 'invite_assist_request',
        request_id: this.id,
        assistant_id: this.selected,
      });

      this.close();

      this.notifyIsLoading = ElNotification({
        title: '',
        duration: 0,
        message: `
          <img src="${IconLoading}" width="25" height="25"><br>
          <strong class="tw-text-left tw-block tw-mt-1">Pozvání do požadavku čeká na přijetí</strong>
        `,
        dangerouslyUseHTMLString: true,
        position: 'bottom-right',
      });
    },
  },
};
</script>

<style scoped>
.DialogBody {
  margin-bottom: 32px;
}

.DialogFooter {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 18px;
}

.UserRow {
  border-bottom: 1px solid theme('colors.border');
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover,
  &.is-selected {
    @apply tw-bg-light;
  }

  &Avatar {
    margin-right: 20px;
  }

  &Name {
    width: 180px;
  }

  &Check {
    flex: 1;
    width: 80px;
    padding-right: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
