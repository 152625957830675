<template>
  <el-popover
    v-model:visible="isOpen"
    placement="bottom"
    trigger="click"
    width="350"
    :persistent="false"
    @after-enter="inputPhoneRef.focus()"
    @hide="form.phone = ''"
  >
    <el-form
      ref="formRef"
      :model="form"
      :rules="formRules"
      class="tw-p-2 tw-w-full"
      @submit.prevent="handleSubmitForm"
    >
      <div class="tw-flex tw-items-center">
        <label
          for="InputPhone"
          class="tw-text-tl-base"
        >
          Zavolat
        </label>
        <el-form-item
          prop="phone"
          class="tw-ml-4 tw-mr-4 tw-mb-0 tw-flex-1"
        >
          <el-input
            id="InputPhone"
            ref="inputPhoneRef"
            v-model="form.phone"
            type="tel"
            size="small"
            placeholder="Např.: +420 720 996 089"
          >
            <template #prefix>
              <icon-phone />
            </template>
          </el-input>
        </el-form-item>
        <el-button
          native-type="submit"
          type="primary"
          circle
        >
          <icon-el-loading
            v-if="isLoading"
            class="tw-animate-spin tw-w-4 tw-h-4 [animation-duration:1.5s]"
          />
          <icon-phone v-else />
        </el-button>
      </div>
    </el-form>
    <template #reference>
      <el-button
        ref="trigger"
        circle
      >
        <icon-phone class="tw-text-primary-500" />
      </el-button>
    </template>
  </el-popover>
</template>

<script setup>
// Refs
const isLoading = ref(false);
const isOpen = ref(false);

const formRef = ref(null);
const inputPhoneRef = ref(null);
const form = ref({
  phone: '',
});

const formRules = ref({
  phone: [
    {
      required: true,
      pattern: /([(+]*[0-9]+[()+. -]*)/g,
      message: ' ',
      trigger: 'blur',
    },
  ],
});

// Methods
const parseNumber = (number) => {
  let parsed = number;
  parsed = parsed.replace(/ /g, '');

  if (parsed.startsWith('00')) {
    parsed = parsed.replace('00', '+');
  }

  if (!parsed.startsWith('+') && parsed.startsWith('42')) {
    parsed = parsed.replace('42', '+42');
  }

  return parsed;
};

const handleSubmitForm = async() => {
  await nextTick();
  form.value = {
    ...form.value,
    phone: parseNumber(form.value.phone),
  };

  const isValid = formRef.value ? await formRef.value.validate() : false;

  if (isValid && !isLoading.value) {
    isLoading.value = true;

    await useOperatorCallingStore().call(form.value.phone);

    isOpen.value = false;
    isLoading.value = false;
  }
};

// Lifecycle
onMounted(() => {
  if (!window.openCallingBox) {
    window.openCallingBox = (number) => {
      form.value.phone = number;
      isOpen.value = true;
      handleSubmitForm();
    };
  }
});

onBeforeUnmount(() => {
  window.openCallingBox = null;
});
</script>
