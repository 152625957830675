<template>
  <div class="tw-pb-2">
    <div
      class="tw-border-b tw-border-border tw-flex tw-items-center"
    >
      <div class="tw-text-primary-500 tw-font-semibold tw-text-base tw-mx-4 tw-my-3">
        Rychlý náhled
      </div>
      <small
        class="tw-text-text-light tw-text-sm tw-font-normal tw-ml-3"
      >
        {{ requests.length }} {{ pluralize(requests.length, 'požadavek', 'požadavky', 'požadavků') }}
      </small>
    </div>
    <div class="tw-overflow-auto tw-max-h-[40vh]">
      <transition-group name="list">
        <OperatorRequestRow
          v-for="request in requests"
          :key="request.id"
          :request="request"
          quick-preview
          type="open"
          class="RequestRow"
        />
      </transition-group>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    requests: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.RequestRow {
  border-bottom: 1px solid theme('colors.border');
  box-shadow: 0;
  margin-bottom: 0;
}
</style>
